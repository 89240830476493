import { ReactComponent as WifiOff } from 'assets/svg/wifiOff.svg'
import { ReactComponent as Wifi1 } from 'assets/svg/wifi1.svg'
import { ReactComponent as Wifi2 } from 'assets/svg/wifi2.svg'
import { ReactComponent as Wifi3 } from 'assets/svg/wifi3.svg'
import { ReactComponent as Wifi4 } from 'assets/svg/wifi4.svg'
import { DeviceType } from 'services/partition/types'
import { ReactNode } from 'react'
import { Icon } from 'components'

interface DeviceInfo {
  image: ReactNode
  type: string
}

const devices: Record<DeviceType, DeviceInfo> = {
  '0': {
    image: <Icon name="device-remote-control" width={110} height={80} />,
    type: 'Controle remoto',
  },
  '1': {
    image: <Icon name="device-photo-sensor" width={110} height={80} />,
    type: 'IVP',
  },
  '2': {
    image: <Icon name="device-photo-sensor" width={110} height={80} />,
    type: 'Fotosensor',
  },
  '4': {
    image: <Icon name="device-magnetic-sensor" width={110} height={80} />,
    type: 'Sensor de abertura',
  },
  '5': {
    image: <Icon name="device-siren" width={110} height={80} />,
    type: 'Sirene',
  },
  '99': {
    image: <Icon name="device-central" width={110} height={80} />,
    type: 'Módulo',
  },
}

export const getDeviceInfo = (deviceType: DeviceType): DeviceInfo | undefined =>
  devices[deviceType === ('6' as DeviceType) ? '99' : deviceType]

export const getWifiCommunicationIcon = (signalForce: number): JSX.Element => {
  if (signalForce > 0 && signalForce <= 40) {
    return <Wifi1 aria-label="wifi-1-icon" />
  }
  if (signalForce > 40 && signalForce <= 60) {
    return <Wifi2 aria-label="wifi-2-icon" />
  }
  if (signalForce > 60 && signalForce <= 80) {
    return <Wifi3 aria-label="wifi-3-icon" />
  }
  if (signalForce > 80 && signalForce <= 100) {
    return <Wifi4 aria-label="wifi-4-icon" />
  }
  return <WifiOff aria-label="wifi-off-icon" />
}

export const getNormalizedDeviceTypeCode = (
  deviceTypeCode?: DeviceType,
): DeviceType | undefined => {
  if (!deviceTypeCode) return undefined
  if (deviceTypeCode === '99') return DeviceType.Central
  return deviceTypeCode as unknown as DeviceType
}

const deviceNumber: Record<DeviceType, number> = {
  [DeviceType.RemoteControl]: 0,
  [DeviceType.PassiveInfraRedSensor]: 1,
  [DeviceType.PassiveInfraPhotoRedSensor]: 2,
  [DeviceType.ReedSwitch]: 4,
  [DeviceType.WirelessSiren]: 5,
  [DeviceType.Central]: 99,
}

export function generateDeviceCode(deviceCode: string, deviceType: DeviceType) {
  const fullCode = `${deviceNumber[deviceType]?.toString(16).padStart(2, '0')}${deviceCode}`

  let sum1 = 0
  let sum2 = 0

  const decimals: number[] = []

  for (let i = 0; i < fullCode.length; i++) {
    const num = parseInt(fullCode[i], 16)

    decimals.push(num)

    sum1 += num * (i + 1)
    sum2 += num * i
  }

  const array = [...decimals, sum1 % 16, sum2 % 16].map((n) => n.toString(16))

  return `${array.slice(0, 2).join('')}-${array.slice(2, -2).join('')}-${array.slice(-2).join('')}`
}
